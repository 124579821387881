.chat_main{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    border: 2px solid red;

    margin-top: 40px;
}
.chat_userinfo{
    border: 2px solid red;
}
.chat_room_info{
    display: flex;
}
.chat_roomid{
    
}
.chat_leave{
    background-color: rgb(255, 66, 66);
    color: white;
    padding: 10px;
    margin-left: 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.chat_board{
    /* border: 2px solid red; */
    padding: 20px;
    margin: 40px 0 15px 0;
    border: 1px solid grey;
    border-radius: 10px;
    max-height: 50vh;
    overflow-y: scroll;
    background-color: #D5FFD0;
}

/* FOR AUTO SCROLL OF CHATS */
.for_auto_scroll{
    /* width: fit-content; */
    height: 50vh;
}

.chat_message_self{
    display: flex;
    justify-content: flex-end;
}
.chat_message_received{
    text-align: left;
    justify-content: flex-start;
}
.li_chat{
    list-style-type: none;
    width: fit-content;
    padding: 10px;
    margin: 5px;
}
.self_chat{
    background-color: #279EFF;
    border-radius: 15px 15px 0px 15px;
}
.received_chat{
    border: 1px solid grey;
    border-radius: 15px 15px 15px 0px;
}
.input_field{
    width: 100%;
    border-radius: 10px;

    display: flex;
    justify-content: space-between;
}
.message_input{
    width: 70%;
    padding: 10px;
    font-size: 15px;
    outline: none;
}
.send_button{
    width: 30%;
    padding: 5px;
    font-size: 15px;
    background-color: rgb(0, 162, 255);
    color: white;
    border: none;
    margin-left: 10px;
}