@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    font-family: 'Poppins', sans-serif;
}


/* Hide Scrollbar */
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}