.livechat_main{

    width: 100%;
    height: 90vh;

    display: flex;
    justify-content: center;
    align-items: center;
}
.joinchat_box{
    display: flex;
    flex-direction: column;
    padding: 20px;
}
.joinchat_h1{
    font-size: 50px;
}
.joinchat_input{
    padding: 10px;
    margin: 5px 0;
}
.joinchat_btn{
    padding: 10px;
    margin-top: 20px;
    background-color: #279EFF;
    font-size: 15px;
    outline: none;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}